import React, { useState } from "react"
import Popover from "@material-ui/core/Popover"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"

import css from "./PopoverMenu.module.scss"

type PopoverButton = {
  text: string
  onClick: () => void
  variant?: string
}

type PropsType = {
  id: string
  buttons: PopoverButton[]
}

export default ({ id, buttons }: PropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  return (
    <>
      <MoreHorizIcon
        onClick={(e) => setAnchorEl(e.currentTarget as unknown as HTMLElement)}
        className={css.horizIcon}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: css.popover }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={css.menu}>
          {buttons.map((button, index) => (
            <div
              key={`${id}-${index}`}
              className={css.menuItem}
              onClick={() => {
                setAnchorEl(null)
                button.onClick()
              }}
              style={button.variant === "red" ? { color: "red" } : {}}
            >
              {button.text}
            </div>
          ))}
        </div>
      </Popover>
    </>
  )
}
