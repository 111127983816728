import React, { useEffect, useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Icon from "@app/components/Icon"
import cx from "classnames"
import css from "./Collapse.module.scss"
import { useAccordionToggle } from "react-bootstrap"

type Props = {
  title: React.ReactNode
  subTitle?: React.ReactNode
  action?: React.ReactNode
  previewContent?: React.ReactNode
  customToggleButton?: React.ReactElement
  variant?: string
  eventKey?: string
  defaultOpen?: boolean
  collapseIcon?: boolean
  className?: string
  children?: any
  disabled?: boolean
  onToggle?: (currentStatus: boolean) => any
  // set disabled to true to use isOpen to override default open/close behavior
  isOpen?: boolean
}

type CustomToggleProps = {
  eventKey: string
  customToggleButton: React.ReactElement
  disabled: boolean
  onClick?: () => void
}

function CustomToggle({
  eventKey,
  customToggleButton,
  onClick,
  disabled,
}: CustomToggleProps) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    onClick?.()
  })

  return (
    <>
      {React.cloneElement(customToggleButton, {
        onClick: disabled ? customToggleButton.props.onClick : decoratedOnClick,
      })}
    </>
  )
}

export default function (props: Props) {
  const {
    title,
    subTitle,
    action,
    previewContent,
    eventKey = "0",
    defaultOpen = false,
    collapseIcon = true,
    onToggle,
    className,
    customToggleButton,
    variant = "primary",
    disabled,
    isOpen,
    children,
  } = props
  const [open, setOpen] = useState<boolean>(defaultOpen)

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen)
    }
  }, [isOpen])

  const handleToggle = (newStatus: boolean) => {
    if (disabled) {
      return
    }

    setOpen(newStatus)
    onToggle?.(newStatus)
  }

  return (
    <div className={css[`collapse-${variant}`]}>
      <Accordion
        activeKey={open ? eventKey : "__none__"}
        className={cx(css.collapse, { [css.expanded]: open }, className)}
      >
        <Accordion.Toggle
          as="div"
          eventKey={eventKey}
          className="collapse-toggle"
        >
          {customToggleButton ? (
            <div
              className={cx("d-flex align-items-center", css.toggleRow, {
                [css.disabled]: disabled,
              })}
            >
              <span className={cx("flex-fill text-truncate", css.title)}>
                {title}
              </span>
              <CustomToggle
                eventKey={eventKey}
                customToggleButton={customToggleButton}
                disabled={disabled || false}
                onClick={() => handleToggle(!open)}
              />
            </div>
          ) : (
            <div
              className={cx("d-flex align-items-center", css.toggleRow, {
                [css.disabled]: disabled,
              })}
              onClick={() => handleToggle(!open)}
            >
              <span className={cx("flex-fill text-truncate", css.title)}>
                {title}
              </span>
              {collapseIcon && (
                <Icon name="chevron-right" className={css.arrowIcon} />
              )}
              {action && <div className={css.action}>{action}</div>}
            </div>
          )}
        </Accordion.Toggle>
        {subTitle}
        {children && (
          <Accordion.Collapse
            eventKey={eventKey}
            className={cx("collapse-content", css.content)}
          >
            <div>{children}</div>
          </Accordion.Collapse>
        )}
        {!open && previewContent && (
          <div className={css.previewContent}>{previewContent}</div>
        )}
      </Accordion>
    </div>
  )
}
