import React from "react"
import Select from "@app/components/Form/Select"
import { TimezoneOptions } from "@app/utils/constants"

type PropType = {
  timezone: string
  onChange: (timezone: string) => void
}

export default (props: PropType) => {
  const { timezone, onChange } = props

  const timezoneSelectOptions = TimezoneOptions.map((timezone) => {
    return {
      id: timezone.zone,
      name: timezone.displayName,
    }
  })

  return (
    <div className="bootstrap4">
      <div className="pt-3 d-flex flex-direction-row">
        <div style={{ width: "300px" }}>
          <Select
            value={timezone}
            options={timezoneSelectOptions}
            onChange={(timezone) => onChange(timezone)}
            clearable={false}
          />
        </div>
      </div>
    </div>
  )
}
