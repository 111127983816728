import { fetch } from "@app/utils/axios"
import { formatDate } from "@app/utils"
export { default as useRequest } from "@app/utils/request"
export * from "@app/utils/request"

import { FormData } from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"

export default {
  connectionTest() {
    return fetch("/api/v2/connection-test")
  },

  // Admin dashboard user lookup
  AdminUserLookupSearchUsers(searchInput: string) {
    return fetch(
      `/api/v2/admin_user_lookup/user_search?userQuery=${searchInput}`
    )
  },
  AdminUserLookupGetUser(userId: number | string) {
    return fetch(`/api/v2/admin_user_lookup/user/${userId}`)
  },

  AdminUserLookupGetUsers(userIds: Array<number | string>) {
    return fetch(
      "/api/v2/admin_user_lookup/user",
      { userids: userIds },
      {
        method: "POST",
      }
    )
  },

  AdminAccountMerge(
    otherUserIds: Array<number | string>,
    primaryUserId: number | string,
    tcUsername?: string,
    tcToken?: string,
    ssoEnabled?: boolean
  ) {
    return fetch(
      "/api/v2/admin_account_merge",
      {
        other_user_ids: otherUserIds,
        primary_user_id: primaryUserId,
        tc_username: tcUsername,
        tc_token: tcToken,
        sso_enabled: ssoEnabled,
      },
      {
        method: "POST",
      }
    )
  },

  AdminUserLookupUpdateUser(
    userId: number | string,
    user: {
      ts_username: string
      tc_username?: string
      tc_token?: string
      sso_enabled?: number
    }
  ) {
    return fetch(`/api/v2/admin_user_lookup/user/${userId}`, user, {
      method: "PUT",
    })
  },

  AdminUserLookupBatchUpdate(
    entities: { id: number; type: string }[],
    updateInfo: { email: string }
  ) {
    return fetch(
      "/api/v2/admin_user_lookup/user/batch",
      {
        entities,
        update_info: updateInfo,
      },
      {
        method: "PUT",
      }
    )
  },

  // Admin dashboard sync errors
  AdminGetSyncFailures() {
    return fetch("/api/v2/admin_sync_failures")
  },

  UpdateSyncError(errorId: string, owner: string, resolveAttempt: boolean) {
    return fetch(
      "/api/v2/admin_sync_failures",
      { sync_errorid: errorId, owner, resolve_attempt: resolveAttempt ? 1 : 0 },
      {
        method: "PUT",
      }
    )
  },

  getSingleCaseError(caseid: number) {
    return fetch(`/api/v2/admin_sync_failures/${caseid}`)
  },

  getSchedulerEmailDataFromSyncFailure(caseid: number) {
    return fetch(`/api/v2/admin_sync_failures/emails/${caseid}`)
  },

  AdminGetSyncErrorComments(errorId: string) {
    return fetch(
      "/api/v2/admin_sync_failures/comments/retrieve",
      { sync_errorid: errorId },
      {
        method: "POST",
      }
    )
  },
  AdminCreateSyncErrorComment(errorId: string, message: string) {
    return fetch(
      "/api/v2/admin_sync_failures/comments/create",
      {
        sync_errorid: errorId,
        message,
      },
      {
        method: "POST",
      }
    )
  },

  // StaffLevels
  getStaffLevels() {
    return fetch("/api/v2/staff_levels")
  },
  getGroupStaffLevels(groupId?: number) {
    const data = groupId ? { groupid: groupId } : {}
    return fetch("/api/v2/group_staff_levels", data)
  },
  postGroupStaffLevels(
    staffLevels: { groupid?: number; staff_level_id: string }[]
  ) {
    return fetch("/api/v2/group_staff_levels", staffLevels, {
      method: "POST",
    })
  },

  // Custom StaffLevels
  createStaffLevel(name: string) {
    return fetch("/api/v2/staff_levels", { name }, { method: "POST" })
  },
  updateStaffLevel(id: string, name: string) {
    return fetch(`/api/v2/staff_levels/${id}`, { name }, { method: "PUT" })
  },
  deleteStaffLevel(id: string) {
    return fetch(`/api/v2/staff_levels/${id}`, {}, { method: "DELETE" })
  },

  // BlockSchedules
  getAnnualBlockSchedules() {
    return fetch("/api/v2/annual_block_schedules")
  },
  getAnnualBlockSchedulesOnMenu(scheduleid?: string) {
    return fetch("/api/v2/annual_block_schedules", {
      scheduleid,
      view_on_list: true,
    })
  },
  getAnnualBlockSchedule(id: string) {
    return fetch(`/api/v2/annual_block_schedules/${id}`)
  },
  createAnnualBlockSchedule(
    data: Omit<AnnualBlockScheduleType, "id" | "display_name">
  ) {
    return fetch("/api/v2/annual_block_schedules", data, { method: "POST" })
  },
  updateAnnualBlockSchedule(
    id: string,
    data: Partial<AnnualBlockScheduleType>
  ) {
    return fetch(`/api/v2/annual_block_schedules/${id}`, data, {
      method: "PUT",
    })
  },
  getTallyTargetsCountModes() {
    return fetch("/api/v2//annual_block_schedules/tally_targets_count_modes")
  },

  // BlockLayouts
  getBlockLayouts() {
    return fetch("/api/v2/block_layouts")
  },

  // BlockYears
  getBlockYears() {
    return fetch("/api/v2/block_years")
  },

  // Current User Info
  getCurrentUserInfo() {
    return fetch("/api/v2/user_info")
  },

  // Rotations
  getBlockScheduleRotations(groupId?: number) {
    const data = groupId ? { groupid: groupId } : {}
    return fetch("/api/v2/block_schedule_rotations", data)
  },
  postBatchUpdateBlockScheduleRotations(
    rotations: Optional<BlockScheduleRotationType, "id" | "color">[]
  ) {
    return fetch(`/api/v2/block_schedule_rotations/batch_update`, rotations, {
      method: "POST",
    })
  },
  deleteBlockScheduleRotation(id: string) {
    return fetch(
      `/api/v2/block_schedule_rotations/${id}`,
      {},
      { method: "DELETE" }
    )
  },

  // Block sets
  getBlockSets(id: string) {
    return fetch(`/api/v2/annual_block_schedules/${id}/block_sets`)
  },
  getBlockSet(id: string) {
    return fetch(`/api/v2/block_sets/${id}`)
  },
  createBlockSet(blockSet: {
    name: string
    annual_block_schedule_id: string
    block_layout_id: string
    count_blocks: number
    staff_level_ids: string[]
  }) {
    return fetch(`/api/v2/block_sets`, blockSet, { method: "POST" })
  },
  updateBlockSet(id: string, blockSet: Partial<BlockSetType>) {
    return fetch(`/api/v2/block_sets/${id}`, blockSet, { method: "PUT" })
  },
  deleteBlockSet(id: string) {
    return fetch(`/api/v2/block_sets/${id}`, {}, { method: "DELETE" })
  },

  // Blocks of Block set
  postBatchUpdateBlocks(
    id: string,
    data: Array<
      | BlocksBatchUpdateAPICreateType
      | BlocksBatchUpdateAPIUpdateType
      | BlocksBatchUpdateAPIDeleteType
    >
  ) {
    data.map(({ data: d }: { data: any }) => {
      d.start_date && (d.start_date = formatDate(d.start_date))
      d.end_date && (d.end_date = formatDate(d.end_date))
    })

    return fetch(`/api/v2/block_sets/${id}/batch_update_blocks`, data, {
      method: "POST",
    })
  },

  // Trainers
  GetListOfTrainers() {
    return fetch("/api/v2/trainer")
  },
  // AnnualBlockScheduleRotations
  getAnnualBlockScheduleRotations(id: string) {
    return fetch(
      `/api/v2/annual_block_schedules/${id}/annual_block_schedule_rotations`
    )
  },
  createAnnualBlockScheduleRotation(
    data: Omit<
      AnnualBlockScheduleRotationType,
      "id" | "block_schedule_rotation" | "block_sets"
    > & { block_set_ids: string[] }
  ) {
    return fetch(`/api/v2/annual_block_schedule_rotations`, data, {
      method: "POST",
    })
  },
  updateAnnualBlockScheduleRotation(
    id: string,
    data: Omit<
      AnnualBlockScheduleRotationType,
      | "id"
      | "annual_block_schedule_id"
      | "block_schedule_rotation"
      | "block_sets"
    >
  ) {
    return fetch(`/api/v2/annual_block_schedule_rotations/${id}`, data, {
      method: "PUT",
    })
  },
  deleteAnnualBlockScheduleRotation(id: string) {
    return fetch(
      `/api/v2/annual_block_schedule_rotations/${id}`,
      {},
      {
        method: "DELETE",
      }
    )
  },
  getAnnualBlockScheduleRotationAssignmentStatistics(id: string) {
    return fetch(
      `/api/v2/annual_block_schedule_rotations/${id}/assignment_statistics`
    )
  },

  // AnnualBLockSchedule Providers
  // Including `block_events`
  getAnnualBlockScheduleProviders(id: string) {
    return fetch(`/api/v2/annual_block_schedules/${id}/providers`)
  },
  getAnnualBlockScheduleProvider(id: string, providerId: number) {
    return fetch(`/api/v2/annual_block_schedules/${id}/providers/${providerId}`)
  },

  // BlockEvents
  getProviderBlockEvents(id: string, providerId: number) {
    return fetch(`/api/v2/annual_block_schedules/${id}/block_events`, {
      providerid: providerId,
    })
  },
  updateBlockEvent(id: string, data: Omit<BlockEventType, "id">) {
    return fetch(`/api/v2/annual_block_schedules/${id}/block_events`, data, {
      method: "POST",
    })
  },
  deleteBlockEvent(id: string, blockEventId: string) {
    return fetch(
      `/api/v2/annual_block_schedules/${id}/block_events/${blockEventId}`,
      {},
      { method: "DELETE" }
    )
  },

  // Templates
  getTemplates(annualBlockScheduleId: string) {
    return fetch(
      `/api/v2/annual_block_schedule/${annualBlockScheduleId}/templates`
    )
  },

  getTemplate(id: string) {
    return fetch(`/api/v2/annual_block_schedule/template/${id}`)
  },

  createTemplate(
    annualBlockScheduleId: string,
    data: AnnualBlockScheduleTemplateType
  ) {
    return fetch(
      `/api/v2/annual_block_schedule/${annualBlockScheduleId}/templates`,
      data,
      {
        method: "POST",
      }
    )
  },

  updateTemplate(id: string, data: Partial<AnnualBlockScheduleTemplateType>) {
    return fetch(`/api/v2/annual_block_schedule/template/${id}`, data, {
      method: "PUT",
    })
  },

  deleteTemplate(id: string) {
    return fetch(
      `/api/v2/annual_block_schedule/template/${id}`,
      {},
      { method: "DELETE" }
    )
  },

  getJobsAndDatesFromTemplate(
    templateId: string,
    blockId: string,
    providerId: number
  ) {
    return fetch(
      `/api/v2/annual_block_schedule/template/${templateId}/apply/jobs`,
      {
        block_set_block_id: blockId,
        providerid: providerId,
      },
      {
        method: "POST",
      }
    )
  },

  applyAssignmentsFromTemplate(
    templateId: string,
    blockEventId: string,
    data?: { jobid: number; providerid: number; start_date: Date }[]
  ) {
    return fetch(
      `/api/v2/annual_block_schedule/template/${templateId}/apply`,
      {
        selected_assignments: data,
        block_event_id: blockEventId,
      },
      {
        method: "POST",
      }
    )
  },

  getEventsFromAppliedTemplate(blockEventId: string) {
    return fetch(
      `/api/v2/annual_block_schedule/block_event/${blockEventId}/templates`
    )
  },

  deleteEventsFromAppliedTemplate(
    appliedTemplateId: string,
    data: { additional_assignment_ids: string[]; eventids: number[] }
  ) {
    return fetch(
      `/api/v2/annual_block_schedule/applied_template_id/${appliedTemplateId}`,
      data,
      {
        method: "DELETE",
      }
    )
  },

  // Jobs
  getJobs(jobTypeId?: number, groupid?: number) {
    return fetch(`/api/v2/jobs`, { job_typeid: jobTypeId, groupid })
  },
  getJob(jobId: number) {
    return fetch(`/api/v2/jobs/${jobId}`)
  },
  createJob(data: Partial<JobWithAllFieldsType>) {
    return fetch("/api/v2/jobs", data, { method: "POST" })
  },
  updateJob(
    jobid: number | string,
    data: Partial<
      Omit<JobWithAllFieldsType, "day_types"> & {
        day_ids: number[]
        day_types: Pick<JobDaytypeType, "dayid" | "day_typeid">[]
        skill_ids: number[]
        provider_type_ids: number[]
      }
    >
  ) {
    return fetch(`/api/v2/jobs/${jobid}`, data, { method: "PUT" })
  },
  deleteJob(id: number) {
    return fetch(`/api/v2/jobs/${id}`, {}, { method: "DELETE" })
  },
  getJobsProviders(jobIds: string, date: string) {
    return fetch(`/api/v2/jobs/providers`, { jobids: jobIds, date })
  },
  getGroupDayTypes() {
    return fetch("/api/v2/jobs/group/day_types")
  },
  getDayTypes() {
    return fetch("/api/v2/day_types")
  },
  getSkills() {
    return fetch("/api/v2/skills")
  },
  getProviderTypes() {
    return fetch("/api/v2/provider_types")
  },
  validateJobsAssignments(data: {
    date: string
    assignments: ManualScheduleJobAssignmentType[]
  }) {
    return fetch(`/api/v2/jobs/validate_assignments`, data, { method: "POST" })
  },
  getRoleTags(tigerconnectSettingId?: string) {
    return fetch("/api/v2/tigerconnect/tags", {
      tigerconnect_setting_id: tigerconnectSettingId,
    })
  },
  getRoles(
    tagToken: string,
    searchTerm: string,
    tigerconnectSettingId?: string
  ) {
    return fetch(
      "/api/v2/tigerconnect/roles",
      {
        tag: tagToken,
        term: searchTerm,
        tigerconnect_setting_id: tigerconnectSettingId,
      },
      { method: "POST" }
    )
  },

  // Job Multi-Seat
  getJobDayAssignments(jobId: number) {
    return fetch(`/api/v2/job_day_assignment/${jobId}`)
  },

  getJobDayAssignmentOverrides(jobId: number, date: string) {
    return fetch(`/api/v2/job_day_assignment/${jobId}/overrides`, { date })
  },

  updateJobDayAssignments(jobId: number, data: any) {
    return fetch(
      `/api/v2/jobs/${jobId}`,
      {
        job_day_assignment: data,
      },
      {
        method: "PUT",
      }
    )
  },

  // Job LinkedRotations
  getJobLinkedRotations(jobId: number) {
    return fetch(`/api/v2/jobs/${jobId}/linked_rotations`)
  },
  getJobLinkedRotation(jobId: number, id: string) {
    return fetch(`/api/v2/jobs/${jobId}/linked_rotations/${id}`)
  },
  createJobLinkedRotation(
    jobId: number,
    data: Pick<
      JobLinkedRotationType,
      "groupid" | "block_schedule_rotation_ids" | "staff_level_ids"
    >
  ) {
    return fetch(`/api/v2/jobs/${jobId}/linked_rotations/`, data, {
      method: "POST",
    })
  },
  updateJobLinkedRotation(
    jobId: number,
    id: string,
    data: Partial<JobLinkedRotationType>
  ) {
    return fetch(`/api/v2/jobs/${jobId}/linked_rotations/${id}`, data, {
      method: "PUT",
    })
  },

  // Groups
  getGroups() {
    return fetch(`/api/v2/groups`)
  },

  // Provider Requests
  updateProviderRequestMultiple(requestStatus: string, requestIDS: number[]) {
    return fetch(
      `/api/v2/provider_request/`,
      { request_status: requestStatus, requestids: requestIDS },
      { method: "PUT" }
    )
  },
  updateProviderRequest(
    requestStatus: string,
    requestId: number,
    schedulerComment: string,
    requestDates: string[],
    requestType: string,
    overrideRequestCount: boolean
  ) {
    return fetch(
      `/api/v2/provider_request/${requestId}`,
      {
        request_status: requestStatus,
        scomment: schedulerComment,
        dates: requestDates,
        request_type: requestType,
        override_request_count: overrideRequestCount,
      },
      { method: "PUT" }
    )
  },
  getProviderRequest(requestId: number) {
    return fetch(`/api/v2/provider_request/${requestId}`, {
      requestid: requestId,
    })
  },
  getProviderRequestTypes() {
    return fetch(`/api/v2/provider_request_types`)
  },

  getOverlaps(requestid: number) {
    return fetch(`/api/v2/provider_request/overlapping_requests/${requestid}`)
  },

  // DutyHourRules
  getDutyHourRules() {
    return fetch(`/api/v2/duty_hour_rules`)
  },
  getDutyHourRule(id: string) {
    return fetch(`/api/v2/duty_hour_rules/${id}`)
  },
  createDutyHourRule(data: Pick<DutyHourRuleType, "staff_level_ids" | "data">) {
    return fetch(`/api/v2/duty_hour_rules`, data, { method: "POST" })
  },
  updateDutyHourRule(id: string, data: Partial<DutyHourRuleType>) {
    return fetch(`/api/v2/duty_hour_rules/${id}`, data, { method: "PUT" })
  },

  // Draft Mode
  getDraftModeSettings() {
    return fetch(`/api/v2/draft_mode_settings`)
  },
  updateDraftModeSettings(
    draftModeEnabled: boolean,
    providerMessage: string,
    schedulerMessage: string
  ) {
    return fetch(
      `/api/v2/draft_mode_settings`,
      {
        draft_mode_enabled: draftModeEnabled,
        provider_message: providerMessage,
        scheduler_message: schedulerMessage,
      },
      { method: "PUT" }
    )
  },
  publishDraftEvents(data: {
    jobids: number[]
    start_date: string
    end_date: string
    notify_scheduled_providers: boolean
    notify_all_providers: boolean
    notify_schedulers: boolean
  }) {
    return fetch("/api/v2/draft_mode/events/publish", data, {
      method: "POST",
    })
  },
  unpublishDraftEvents(draftPublishingID: number) {
    return fetch(
      `/api/v2/draft_mode/events/unpublish/${draftPublishingID}`,
      {},
      { method: "POST" }
    )
  },
  getDraftPublishing() {
    return fetch(`/api/v2/draft_mode/publishing`)
  },

  // AnnualBLockScheduleTallies
  getAnnualBLockScheduleTallies(id: string) {
    return fetch(
      `/api/v2/annual_block_schedules/${id}/annual_block_schedule_tallies`
    )
  },
  createAnnualBlockScheduleTally<T extends AnnualBlockScheduleTallyType>(
    data: Omit<T, "id" | "annual_block_schedule_rotations">
  ) {
    return fetch(`/api/v2/annual_block_schedule_tallies`, data, {
      method: "POST",
    })
  },
  updateAnnualBlockScheduleTally(
    id: string,
    data: Partial<AnnualBlockScheduleTallyType>
  ) {
    return fetch(`/api/v2/annual_block_schedule_tallies/${id}`, data, {
      method: "PUT",
    })
  },
  //user-permissions/menu-items
  getUserMenuItems(entityid: number, entity_type: string) {
    return fetch(`/api/v2/user_menu_items`, {
      entityid,
      entity_type,
    })
  },
  updateUserMenuItems(entityid: number, entity_type: string, data: any) {
    return fetch(`/api/v2/user_menu_items`, data, {
      method: "POST",
      params: { entityid, entity_type },
    })
  },

  // tigerconnect settings in group setting
  getTigerconnectSettings() {
    return fetch("/api/v2/tigerconnect_settings")
  },
  getTigerconnectSetting(id: string) {
    return fetch(`/api/v2/tigerconnect_settings/${id}`)
  },
  createTigerconnectSetting(
    data: Omit<TigerconnectSettingType, "id" | "groupid">
  ) {
    return fetch("/api/v2/tigerconnect_settings", data, {
      method: "POST",
    })
  },
  updateTigerconnectSetting(
    id: string,
    data: Omit<TigerconnectSettingType, "id" | "groupid">
  ) {
    return fetch(`/api/v2/tigerconnect_settings/${id}`, data, {
      method: "PUT",
    })
  },
  deleteTigerconnectSetting(id: string) {
    return fetch(
      `/api/v2/tigerconnect_settings/${id}`,
      {},
      {
        method: "DELETE",
      }
    )
  },

  // tigerschedule api settings in group setting
  getTigerscheduleApikey() {
    return fetch(`/api/v2/tigerschedule_apikey`)
  },
  updateTigerscheduleApikey() {
    return fetch(
      "/api/v2/tigerschedule_apikey",
      {},
      {
        method: "PUT",
      }
    )
  },

  //tigerconnect integrations
  createNewTCIntegrationId(
    organization_id: string,
    tigerconnect_key: string,
    tigerconnect_secret: string
  ) {
    return fetch(
      "/api/v2/tigerconnect_integration/create",
      {
        organization_id: organization_id,
        tigerconnect_key: tigerconnect_key,
        tigerconnect_secret: tigerconnect_secret,
      },
      { method: "POST" }
    )
  },

  // onViewNoteUers
  onViewNoteUserLogin(data: {
    username: string
    password: string
    calendar_key: string
  }) {
    return fetch("/api/v2/on_view_note_users/login", data, { method: "POST" })
  },
  onViewNoteUserLogout() {
    return fetch("/api/v2/on_view_note_users/logout", {}, { method: "POST" })
  },

  // Custom Calendar
  getCustomCalendar(calendarKey: string) {
    return fetch(`/api/v2/custom_calendars/${calendarKey}`)
  },
  getCustomCalendarGroups(calendarKey: string) {
    return fetch(`/api/v2/custom_calendars/${calendarKey}/groups`)
  },
  getCustomCalendarGroupDetails(
    calendarKey: string,
    groupId: number,
    data?: { date: string }
  ) {
    return fetch(
      `/api/v2/custom_calendars/${calendarKey}/groups/${groupId}`,
      data
    )
  },
  getCustomCalendarProvider(calendarKey: string, providerId: number) {
    return fetch(
      `/api/v2/custom_calendars/${calendarKey}/providers/${providerId}`
    )
  },
  postCustomCalendarProviderTCMessage(
    calendarKey: string,
    data: {
      providerid?: number
      job_role_token_id?: string
      // message params
      tigertext_from?: string
      tigertext_callback?: string
      tigertext_message: string
    }
  ) {
    return fetch(
      `/api/v2/custom_calendars/${calendarKey}/secure_message`,
      data,
      { method: "POST" }
    )
  },
  getDailyArchive(
    calendarKey: string,
    groupId: number,
    data?: { date: string }
  ) {
    return fetch(
      `/api/v2/custom_calendars/${calendarKey}/groups/${groupId}/daily_archive`,
      data
    )
  },
  createScheduleChange(
    calendarKey: string,
    data: {
      groupid?: number
      jobid?: number
      contact: string
      providerid1?: number
      providerid2?: number
      start_time: string
      end_time: string
      provider_contact_1?: string
      provider_contact_2?: string
      provider_contact_3?: string
      provider_contact_pager?: string
      provider_contact_special?: string
      notes?: string
      notification_email_addresses?: string
      action?: string
    }
  ) {
    return fetch(`/api/v2/custom_calendars/${calendarKey}/notes`, data, {
      method: "POST",
    })
  },
  getGroupProviders(groupId: number) {
    return fetch(`/api/v2/groups/${groupId}/providers`)
  },
  getGroupSchedulers(groupId: number) {
    return fetch(`/api/v2/groups/${groupId}/schedulers`)
  },
  rolloverAnnualSchedule(
    id: string,
    data: {
      name: string
      start_date: string
      end_date: string
      staff_level_mappings: {
        previous_staff_level: string
        previous_staff_level_id: string
        target_start_level?: string
        target_start_level_id?: string
      }[]
      override: boolean
    }
  ) {
    return fetch(`/api/v2/annual_block_schedules/${id}/rollover`, data, {
      method: "POST",
    })
  },
  rolloverAnnualScheduleCheck(id: string) {
    return fetch(`/api/v2/annual_block_schedules/${id}/rollover_check`)
  },

  // Block schedule reports
  getBlockScheduleReport(
    reportType: "daily_assignments" | "rotations" | "rotations_summary",
    groupId: number,
    filterType: "action" | "affected",
    startDate: string,
    endDate: string,
    rotations?: string[],
    residents?: number[]
  ) {
    return fetch(
      "/api/v2/rotation_logs",
      {
        report_type: reportType,
        groupid: groupId,
        filter_type: filterType,
        start_date: startDate,
        end_date: endDate,
        rotations,
        residents,
        as_csv: true,
      },
      {
        method: "POST",
      }
    )
  },
  getAdditionalAssignmentDailyOverrides(jobid: string) {
    return fetch(
      "/api/v2/additional_assignment_daily_overrides",
      {
        jobid: jobid,
      },
      { method: "GET" }
    )
  },
  createAdditionalAssignmentDailyOverride(
    data: Omit<AdditionalAssignmentDailyOverride, "id">
  ) {
    return fetch("/api/v2/additional_assignment_daily_overrides", data, {
      method: "POST",
    })
  },
  updateAdditionalAssignmentDailyOverride(
    id: string,
    data: Omit<AdditionalAssignmentDailyOverride, "id">
  ) {
    return fetch(`/api/v2/additional_assignment_daily_overrides/${id}`, data, {
      method: "PUT",
    })
  },
  deleteAdditionalAssignmentDailyOverride(id: string) {
    return fetch(
      `/api/v2/additional_assignment_daily_overrides/${id}`,
      {},
      {
        method: "DELETE",
      }
    )
  },

  getMenu() {
    return fetch("/api/v2/menu_items")
  },

  // :::::::::Calendar V2:::::::::
  getAssignments(start_date: string, end_date: string) {
    return fetch(`/api/v2/events?startdate=${start_date}&enddate=${end_date}`)
  },
  getEvents(date: string) {
    return fetch(`/api/v2/events?date=${date}`)
  },
  getJobsAssignments() {
    return fetch("/api/v2/jobs")
  },
  getDayoffs(startDate: string, endDate: string) {
    return fetch(`/api/v2/dayoffs?start_date=${startDate}&end_date=${endDate}`)
  },
  getVacations(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/vacations?start_date=${startDate}&end_date=${endDate}`
    )
  },
  getHolidays() {
    return fetch("/api/v2/holidays")
  },
  getPendingRequests(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/requests?start_date=${startDate}&end_date=${endDate}&status=Pending,Provisional`
    )
  },
  getProviderList(startDate: string, jobId: number) {
    return fetch(
      "/api/v3/internal/provider-list",
      {
        jobid: jobId,
        date: startDate,
      },
      {
        method: "POST",
      }
    )
  },
  createSingleAssignment(event: {
    edate: string
    jobid: number
    providerid: number
  }) {
    return fetch("/api/v3/internal/event", event, {
      method: "POST",
    })
  },
  getChangeRoles() {
    return fetch("/api/v2/change_role")
  },
  postChangeRoles(entityidRoleid: string, clincId: number) {
    return fetch(
      "/api/v2/change_role",
      { entityid_roleid: entityidRoleid, clinicid: clincId },
      {
        method: "POST",
      }
    )
  },
  updateSingleAssignment(event: {
    jobid: number
    providerid: number
    edate: string
    existingProviderid?: number
  }) {
    const { existingProviderid, ...eventData } = event
    return fetch(
      "/api/v3/internal/event",
      existingProviderid
        ? { ...eventData, existing_providerid: event.existingProviderid }
        : event,
      {
        method: "PUT",
      }
    )
  },
  updateMultipleAssignments(
    jobid: number,
    edate: string,
    providerids: number[]
  ) {
    return fetch(
      "/api/v3/internal/multiple-assignments",
      {
        jobid,
        start_date: edate,
        providerids,
      },
      {
        method: "PUT",
      }
    )
  },
  updateDraftMultipleAssignments(
    jobid: number,
    edate: string,
    providerids: number[]
  ) {
    return fetch(
      "/api/v3/internal/draft-multiple-assignments",
      {
        jobid,
        start_date: edate,
        providerids,
      },
      {
        method: "PUT",
      }
    )
  },

  createMultipleAssignemnts(
    jobid: number,
    edate: string,
    providerids: number[]
  ) {
    return fetch(
      "/api/v3/internal/multiple-assignments",
      {
        jobid,
        start_date: edate,
        providerids,
      },
      {
        method: "POST",
      }
    )
  },
  createDraftMultipleAssignemnts(
    jobid: number,
    edate: string,
    providerids: number[]
  ) {
    return fetch(
      "/api/v3/internal/draft-multiple-assignments",
      {
        jobid,
        start_date: edate,
        providerids,
      },
      {
        method: "POST",
      }
    )
  },

  getSchedulingRules() {
    return fetch(`/api/v2/scheduling_rules`)
  },

  updateSchedulingRules(data: {
    rules: string[]
    apply_scheduling_rule?: boolean
    draft_mode_scheduling?: boolean
    calendar_start_date?: number
    display_job_times?: boolean
    compact_list_view?: boolean
  }) {
    return fetch(`/api/v2/scheduling_rules`, data, { method: "POST" })
  },

  // API V3
  getProviders() {
    return fetch(`/api/v3/providers`)
  },

  getProviderById(providerId: number) {
    return fetch(`/api/v2/providers/${providerId}`)
  },

  getJobAvailableProviders(jobId: number) {
    return fetch(`/api/v3/internal/jobs/${jobId}/providers`)
  },

  updateSplitShifts(
    edate: string,
    jobid: number,
    splitShifts: {
      providerid: number
      starttime: string
      endtime: string
      tally_credit: number
    }[],
    action: "create" | "update"
  ) {
    return fetch(
      "/api/v3/internal/split-shift",
      {
        job_edate: edate,
        jobid,
        split_shifts: splitShifts,
      },
      {
        method: action === "create" ? "POST" : "PUT",
      }
    )
  },

  deleteEvent(eventid: number) {
    return fetch(
      `/api/v3/internal/event/${eventid}`,
      {},
      {
        method: "DELETE",
      }
    )
  },

  getDraftEvents(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/draft_events?startdate=${startDate}&enddate=${endDate}`
    )
  },

  getFlags(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/schedule_dates?startdate=${startDate}&enddate=${endDate}`
    )
  },
  createFlag(date: string, jobid: number) {
    return fetch(
      "/api/v3/internal/schedule_dates",
      {
        date,
        jobid,
      },
      {
        method: "POST",
      }
    )
  },
  deleteFlag(date: string, jobid: number) {
    return fetch(
      "/api/v3/internal/schedule_dates",
      {
        date,
        jobid,
      },
      {
        method: "DELETE",
      }
    )
  },
  publishSingleDraft(draftEventId: number) {
    return fetch(
      `/api/v3/internal/draft_events/publish/${draftEventId}`,
      {},
      {
        method: "POST",
      }
    )
  },
  unPublishSingleDraft(eventId: number) {
    return fetch(
      `/api/v3/internal/draft_events/unpublish/${eventId}`,
      {},
      {
        method: "POST",
      }
    )
  },
  createDraftEvent(event: {
    jobid: number
    providerid: number
    edate: string
  }) {
    return fetch("/api/v3/internal/draft_events", event, {
      method: "POST",
    })
  },
  updateDraftEvent(draftEvent: {
    jobid: number
    providerid: number
    edate: string
    existingProviderid?: number
  }) {
    const { existingProviderid, ...draftEventData } = draftEvent
    return fetch(
      "/api/v3/internal/draft_events",
      existingProviderid
        ? { ...draftEventData, existing_providerid: existingProviderid }
        : draftEvent,
      {
        method: "PATCH",
      }
    )
  },
  deleteDraftEvent(draftEventId: number) {
    return fetch(
      `/api/v3/internal/draft_events/${draftEventId}`,
      {},
      {
        method: "DELETE",
      }
    )
  },
  getJobMultipleAssignmentSettings(jobId: number, date: string) {
    return fetch(`/api/v3/internal/settings/job/${jobId}/date/${date}`)
  },
  getRangeJobMultipleAssignmentSettings(
    jobId: number,
    start_date: string,
    end_date: string
  ) {
    return fetch(
      `/api/v3/internal/settings/job/${jobId}?start_date=${start_date}&end_date=${end_date}`
    )
  },
  getJobsMultipleAssignmentSettings(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/settings/jobs?startdate=${startDate}&enddate=${endDate}`
    )
  },
  getRequestStatuses() {
    return fetch(`/api/v3/internal/request_statuses`)
  },
  getRequestTypes() {
    return fetch(`/api/v3/internal/request_types`)
  },
  getDayOffTypes() {
    return fetch(`/api/v3/internal/dayoff_types`)
  },
  getMyClinicPermissions(clinicid: number, permission: string) {
    return fetch(
      `/api/v3/internal/clinic-permission/clinic/${clinicid}/${permission}`
    )
  },
  getClinicPermissions(userid: number) {
    return fetch(`/api/v3/internal/clinic-permission/${userid}`)
  },
  createClinicPermission(data: {
    userid: number
    clinicid: number
    permission: string
  }) {
    return fetch(`/api/v3/internal/clinic-permission`, data, { method: "POST" })
  },
  deleteClinicPermission(id: string) {
    return fetch(
      `/api/v3/internal/clinic-permission/${id}`,
      {},
      { method: "DELETE" }
    )
  },
  getNotesList(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/notes?start_date=${startDate}&end_date=${endDate}`
    )
  },
  createNote(data: {
    note: string
    noteDate: string
    groupid: number
    userid: number
    showOnCalendar: boolean
  }) {
    return fetch(
      "/api/v3/internal/notes",
      {
        note: data.note,
        note_date: data.noteDate,
        groupid: data.groupid,
        userid: data.userid,
        show_on_calendar: data.showOnCalendar,
      },
      { method: "POST" }
    )
  },
  updateNote(
    noteid: number,
    data: { note: string; show_on_calendar: boolean }
  ) {
    return fetch(`/api/v3/internal/notes/${noteid}`, data, { method: "PUT" })
  },
  deleteNote(noteid: number) {
    return fetch(`/api/v3/internal/notes/${noteid}`, {}, { method: "DELETE" })
  },

  createEventsBatch(events: {
    data: FormData["formElements"]
    track_changes?: boolean
    schedule_and_notify?: {
      all_schedulers: boolean
      all_providers: boolean
      provider_ids: number[]
      emails: string[]
    }
  }) {
    return fetch("/api/v3/internal/batch_event", events, { method: "POST" })
  },
  getCalendarFilter() {
    return fetch(`/api/v3/internal/calendar_filters`)
  },
  saveCalendarFilter(data: {
    provider_filter?: Object
    job_filter?: Object
    dayoff_filter?: Object
    highlights?: Object
  }) {
    return fetch(`/api/v3/internal/calendar_filters`, data, { method: "POST" })
  },
  getScheduleChanges(startDate: string, endDate: string) {
    return fetch(
      `/api/v3/internal/schedule_changes?start_date=${startDate}&end_date=${endDate}`
    )
  },
  saveTemplate(data: {
    template_name: string
    start_date: string
    end_date: string
  }) {
    return fetch("/api/v2/save_template/", data, { method: "POST" })
  },
  unschedule(data: {
    jobid: number
    providerid: number
    start_date: string
    end_date: string
    preserve_holidays: string
    published_events: boolean
    draft_events: boolean
    all_days: boolean
    day_of_week: string[]
  }) {
    return fetch("/api/v3/internal/unschedule/", data, { method: "POST" })
  },
  eraseFlags(data: {
    jobid: number
    start_date: string
    end_date: string
    preserve_holidays: string
    all_days: boolean
    day_of_week: string[]
  }) {
    return fetch("/api/v3/internal/erase_flags/", data, { method: "POST" })
  },
  scheduleRange(data: {
    jobid: number | string
    start_date: string
    end_date: string
    providerids: number[]
    draft_mode: boolean
  }) {
    return fetch("/api/v3/internal/schedule_range/", data, { method: "POST" })
  },
  getGroupsByClinic(clinicId: number, page?: number, per?: number) {
    const url = `/api/v3/internal/groups?clinicid=${clinicId}${
      page && per ? `&page=${page}&per=${per}` : ""
    }`
    return fetch(url)
  },
  getAllClinics() {
    return fetch(`/api/v3/internal/clinics`)
  },
  getAllClinicsByPermission(userid: number, permission: string) {
    return fetch(`/api/v3/internal/clinics/permission/${userid}/${permission}`)
  },
  getGroupSettings(groupid: number) {
    return fetch(`/api/v3/internal/group_settings?groupid=${groupid}`)
  },
  updateGroupById(groupid: number, data: any) {
    return fetch(`/api/v3/internal/groups/${groupid}`, data, {
      method: "POST",
    })
  },
  updateGroupSettings(groupid: number, data: any) {
    return fetch(`/api/v3/internal/group_settings/${groupid}`, data, {
      method: "POST",
    })
  },
  getVendorIntegration(id: string) {
    return fetch(`/api/v3/internal/vendor_integrations/${id}`)
  },
  createVendorIntegrationMapping(
    vendorIntegrationMapping: Partial<VendorIntegrationMapping>
  ) {
    return fetch(
      "/api/v3/internal/integration_mappings",
      vendorIntegrationMapping,
      {
        method: "POST",
      }
    )
  },
  updateVendorIntegrationMapping(
    integrationMappingId: string,
    vendorIntegrationMapping: Partial<VendorIntegrationMapping>
  ) {
    return fetch(
      `/api/v3/internal/integration_mappings/${integrationMappingId}`,
      vendorIntegrationMapping,
      {
        method: "PATCH",
      }
    )
  },
  getVendorIntegrationMappings(vendorIntegrationId: string) {
    return fetch(
      `/api/v3/internal/integration_mappings/vendor_integration/${vendorIntegrationId}`
    )
  },
  getAllVendorIntegrationMappings() {
    return fetch(`/api/v3/internal/integration_mappings`)
  },
  deleteVendorIntegrationMapping(vendorIntegrationMappingId: string) {
    return fetch(
      `/api/v3/internal/integration_mappings/${vendorIntegrationMappingId}`,
      {},
      {
        method: "DELETE",
      }
    )
  },
  saveDefaultClinicSettings(data: any) {
    return fetch(`/api/v3/internal/clinic_settings/batch`, data, {
      method: "POST",
    })
  },
  getDefaultsClinicSettings(clinicid: number) {
    return fetch(`/api/v3/internal/clinic_settings/${clinicid}`)
  },
  getProvidersContactInfoByGroup(groupid: number) {
    return fetch(`/api/v3/internal/provider/contact/${groupid}`)
  },
  getDraftModeSettingsByGroup(groupid: number) {
    return fetch(`/api/v3/internal/draft-mode-settings/${groupid}`)
  },
  canDisableDraftMode(groupid: number, data: any) {
    return fetch(
      `/api/v3/internal/draft-mode-settings/can-disable/${groupid}`,
      data,
      {
        method: "POST",
      }
    )
  },
  searchTigerconnectOrganizations(searchTerm: string) {
    return fetch(`/api/v3/internal/tigerconnect-search/orgs/${searchTerm}`)
  },
  getTigerconnectClinicSettings(clinicId: number, groupId?: number) {
    let url = `/api/v3/internal/tigerconnect_clinic_settings?clinicId=${clinicId}`
    if (groupId) {
      url += `&groupId=${groupId}`
    }
    return fetch(url)
  },
  createTigerconnectClinicSetting(
    clinicId: number,
    data: TigerconnectClinicSetting
  ) {
    return fetch(
      `/api/v3/internal/tigerconnect_clinic_settings`,
      { clinicid: clinicId, ...data },
      {
        method: "POST",
      }
    )
  },
  propagateTigerconnectClinicSetting(settingId: string, clinicId: number) {
    return fetch(
      `/api/v3/internal/tigerconnect_clinic_settings/propagate/setting/${settingId}/clinic/${clinicId}`,
      {},
      {
        method: "POST",
      }
    )
  },
  importTigerconnectSettings(clinicId: number) {
    return fetch(
      `/api/v3/internal/tigerconnect_clinic_settings/import/clinic/${clinicId}`,
      {},
      {
        method: "POST",
      }
    )
  },
  deleteTigerconnectClinicSetting(id: string) {
    return fetch(
      `/api/v3/internal/tigerconnect_clinic_settings/${id}`,
      {},
      {
        method: "DELETE",
      }
    )
  },
  createTigerconnectSettings(tigerconnectSettings: TigerconnectGroupSetting[]) {
    return fetch(
      "/api/v3/internal/tigerconnect_settings/batch",
      tigerconnectSettings,
      { method: "POST" }
    )
  },
  createGroup(data: {
    clinicid: number
    name: string
    abbrev: string
    priority: number
    timezone: string
    daylight_savings: boolean
    add_as_scheduler: boolean
  }) {
    return fetch("/api/v3/internal/groups", data, {
      method: "POST",
    })
  },
  getUserManagementByClinic(clinicid: number, page: number = 1) {
    return fetch(
      `/api/v3/internal/user-management/search?clinicId=${clinicid}&page=${page}`
    )
  },
}
