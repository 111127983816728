import { useSelector } from "@app/models"
import { authPermission } from "@app/services/accessPermission"

// Hook for Can I visit specific page/path
export const useAccessPermission = (permissionConfig: PlainObjectType = {}) => {
  const { currentUser } = useSelector((state) => state.users)

  const canAccess = (path: string) =>
    authPermission(currentUser, path, permissionConfig)

  return { canAccess }
}
