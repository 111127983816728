import React, { useEffect, useState, useMemo } from "react"
import Table from "@app/components/Table"
import api, { useRequest } from "@app/services/api"
import { useSelector } from "@app/models"
import { Select } from "@app/components/Form"
import css from "./UserManagementTable.module.scss"

interface UserData {
  userid: number
  username: string
  email: string
  firstname: string
  lastname: string
  tc_token: string
  avatar: boolean
  created_at: string
  provider_groups: number
  scheduler_groups: number
  groups: number[]
}

const PAGE_SIZE = 50

const UserManagementTable: React.FC = () => {
  const { clinicid, clinic } = useSelector((state) => state.users.currentUser)
  const [allUserData, setAllUserData] = useState<UserData[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [selectedGroups, setSelectedGroups] = useState<number[]>([])

  const { data: firstPageData, isLoading } = useRequest<UserData[]>(
    clinicid ? [api.getUserManagementByClinic, clinicid, 1] : null
  )

  useEffect(() => {
    if (!firstPageData || firstPageData.length < PAGE_SIZE) {
      setAllUserData(firstPageData || [])
      return
    }

    const loadMorePages = async () => {
      const pages: UserData[] = []
      let page = 2
      let hasMore = true

      while (hasMore) {
        const newPageData = await api.getUserManagementByClinic(clinicid, page)
        if (newPageData.length < PAGE_SIZE) hasMore = false
        pages.push(...newPageData)
        page++
      }

      setAllUserData([...firstPageData, ...pages])
    }

    loadMorePages()
  }, [firstPageData, clinicid])

  const uniqueGroups = useMemo(() => {
    const groupSet = new Set<number>()
    allUserData.forEach((user) => {
      if (user.groups) {
        user.groups.forEach((group) => groupSet.add(group))
      }
    })
    return Array.from(groupSet).sort((a, b) => a - b)
  }, [allUserData])

  const filteredData = useMemo(() => {
    const lowerSearchTerm = searchTerm.toLowerCase()
    return allUserData.filter((user) => {
      const matchesSearch = [
        user.username || "",
        user.email || "",
        user.firstname || "",
        user.lastname || "",
        user.tc_token || "",
      ].some((field) => field.toLowerCase().includes(lowerSearchTerm))

      const matchesGroups =
        selectedGroups.length === 0 ||
        (user.groups &&
          selectedGroups.some((group) => user.groups?.includes(group)))

      return matchesSearch && matchesGroups
    })
  }, [allUserData, searchTerm, selectedGroups])

  const totalItems = filteredData.length
  const startIndex = (currentPage - 1) * PAGE_SIZE
  const endIndex = Math.min(startIndex + PAGE_SIZE, totalItems)

  const paginatedData = useMemo(
    () => filteredData.slice(startIndex, endIndex),
    [filteredData, startIndex, endIndex]
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm, selectedGroups])

  return (
    <div className={css.tableContainer}>
      <div className={css.controls}>
        <h2 className={css.clinicTitle}>Clinic: {clinic?.name || "N/A"}</h2>

        <div className={css.buttonContainer}>
          <button className={css.addGroupButton} disabled>
            Add User
          </button>
          <div className={css.filtersContainer}>
            <div className="bootstrap4">
              <div className="d-flex">
                <Select
                  searchable
                  value={selectedGroups[0] || null}
                  options={uniqueGroups.map((group) => ({
                    id: group,
                    name: `${group}`,
                  }))}
                  onChange={(value) => setSelectedGroups(value ? [value] : [])}
                  className={css.groupFilter}
                  placeholder="Filter by Group"
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="Search by username, email, first name, last name or TC token"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={css.searchInput}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className={css.loadingMessage}>Loading...</div>
      ) : paginatedData.length > 0 ? (
        <Table
          tableLayout="fixed"
          variant="spaceBetween"
          loading={isLoading}
          data={paginatedData}
          columns={[
            { dataKey: "userid", title: "User ID" },
            { dataKey: "username", title: "Username", sorter: true },
            { dataKey: "email", title: "Email", sorter: true },
            { dataKey: "firstname", title: "First Name", sorter: true },
            { dataKey: "lastname", title: "Last Name", sorter: true },
            { dataKey: "tc_token", title: "TC Token" },
            {
              dataKey: "avatar",
              title: "Avatar",
              render: (_, user) => (user.avatar ? "Yes" : "No"),
            },
            {
              dataKey: "created_at",
              title: "Created At",
              sorter: true,
              render: (_, user) =>
                new Date(user.created_at).toLocaleDateString(),
            },
            { dataKey: "provider_groups", title: "Provider Groups" },
            { dataKey: "scheduler_groups", title: "Scheduler Groups" },
          ]}
        />
      ) : (
        <div className={css.noDataMessage}>No data available</div>
      )}

      <div className={css.paginationControls}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <div className={css.paginationInfo}>
          {startIndex + 1}-{endIndex} of {totalItems}
        </div>
        <button
          disabled={currentPage * PAGE_SIZE >= totalItems}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default UserManagementTable
