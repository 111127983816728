import React, { useEffect, useState } from "react"
import { FormItem, Input } from "@app/components/Form"
import OrgCollapse from "./OrgCollapse"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import Loader from "@app/components/Loader"
import { H5 } from "@app/components/Typography"
import css from "./SearchOrgs.module.scss"

type OrgResults = {
  name: string
  token: string
}[]

export default ({ clinicId }: { clinicId: number }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [orgSearchLoading, setOrgSearchLoading] = useState(false)
  const [orgResults, setOrgResults] = useState<OrgResults>([])

  const { data: existingSettings, mutate } = useRequest<
    TigerconnectClinicSetting[]
  >([api.getTigerconnectClinicSettings, clinicId])

  useEffect(() => {
    if (searchTerm.length > 3) {
      setOrgSearchLoading(true)
      api.searchTigerconnectOrganizations(searchTerm).then((results) => {
        const existingSettingOrgTokens = existingSettings?.map(
          (setting) => setting.organization_token
        )
        setOrgResults(
          results.filter((result: any) =>
            !existingSettingOrgTokens
              ? true
              : !existingSettingOrgTokens.includes(result.token)
          )
        )
        setOrgSearchLoading(false)
      }, handleApiError)
    }
  }, [searchTerm, existingSettings])

  const importExistingOrgs = () => {
    setOrgSearchLoading(true)
    api
      .importTigerconnectSettings(clinicId)
      .then((_res) => {
        mutate()
        setOrgSearchLoading(false)
      })
      .catch((err) => {
        setOrgSearchLoading(false)
        handleApiError(err)
      })
  }

  return (
    <div>
      <div className="d-flex flex-row-reverse">
        <H5 onClick={importExistingOrgs} bold className={css.importOrgButton}>
          Import Existing Org Configurations
        </H5>
      </div>
      <FormItem label="Add orgs" layout="vertical">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Organizations"
        />
      </FormItem>
      {existingSettings?.map((setting, index) => (
        <OrgCollapse
          key={`${setting.organization_token}_${index}`}
          label={setting.label}
          tigerconnectKey={setting.tigerconnect_key}
          tigerconnectSecret={setting.tigerconnect_secret}
          orgToken={setting.organization_token}
          settingId={setting.id}
          mutate={mutate}
          clinicId={clinicId}
          defaultEditing={true}
        />
      ))}
      {orgSearchLoading && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}
      {orgResults.map((org) => (
        <OrgCollapse
          key={org.token}
          mutate={mutate}
          clinicId={clinicId}
          label={org.name}
          orgToken={org.token}
        />
      ))}
    </div>
  )
}
