import React, { useState } from "react"
import { useParams } from "react-router-dom"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import css from "./UnmappedItems.module.scss"
import UpdateCreateMappingModal from "../mappings/components/UpdateCreateMappingsModal/UpdateCreateMappingModal"

export default () => {
  const { id } = useParams()
  const [selectedMapping, setSelectedMapping] =
    useState<VendorIntegrationMapping>()

  const { data: mappings, mutate } = useRequest([
    api.getVendorIntegrationMappings,
    id,
  ])
  const [showModal, setShowModal] = useState(false)

  const handleOnHide = () => {
    setShowModal(false)
    setSelectedMapping(undefined)
  }

  return (
    <>
      <Table
        variant="spaceBetween"
        data={
          mappings?.filter(
            (mapping: VendorIntegrationMapping) =>
              !mapping.internal_id || mapping.internal_id.trim() === ""
          ) || []
        }
        columns={[
          {
            dataKey: "vendor_integrationid",
            title: "Integration ID",
          },
          {
            dataKey: "external_id",
            title: "External ID",
          },
          {
            dataKey: "external_name",
            title: "External Name",
          },
          {
            dataKey: "updated_at",
            title: "Timestamp",
            sorter: true,
          },
          {
            title: "Operations",
            render: (_: any, mapping: VendorIntegrationMapping) => (
              <Button
                className={css.mapItemButton}
                onClick={() => {
                  setSelectedMapping(mapping)
                  setShowModal(true)
                }}
              >
                Map item
              </Button>
            ),
          },
        ]}
      />

      <UpdateCreateMappingModal
        vendorIntegrationId={selectedMapping?.vendor_integrationid || ""}
        show={showModal}
        selectedMapping={selectedMapping}
        mutate={mutate}
        onHide={handleOnHide}
        unmappedItem={true}
      />
    </>
  )
}
