import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "@app/models"
import Menu from "@app/containers/layouts/Menu"
import { Container } from "@app/components/Layout"
import { useAccessPermission, useIsAuthenticated } from "@app/utils/hooks"
import css from "./UserManagement.module.scss"
import Loader from "@app/components/Loader"
import UserManagementTable from "./UserManagementTable/UserManagementTable"

const UserManagement = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated()
  const { canAccess } = useAccessPermission({ scope: "userManagement" })
  const { currentUser } = useSelector((state) => state.users)

  useEffect(() => {
    ;(async () => {
      await dispatch.users.getCurrentUserInfo()
    })()
  }, [])

  useEffect(() => {
    if (!isAuthenticated) return

    if (!canAccess("/enterprise/users")) {
      window.location.href = "/menu.cgi"
    } else {
      setIsLoading(false)
    }
  }, [isAuthenticated, canAccess])

  return (
    <Fragment>
      <Menu userInfo={currentUser} />
      <Container size="fluid" className={css.userManagementContainer}>
        {isLoading ? (
          <div className={css.loadingContainer}>
            <Loader animation="dots" />
          </div>
        ) : (
          <UserManagementTable />
        )}
      </Container>
    </Fragment>
  )
}

export default UserManagement
