import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import store from "@app/models"
import ScrollToTop from "@app/containers/common/ReactRouterScrollToTop"
import NotFound from "@app/containers/common/NotFound"
import MergedDailyWhiteboardPage from "./mdv/MergedDailyWhiteboardPage"
import AnnualScheduleWhitebardPage from "../block-scheduling/WhiteboardPage/Whiteboard"
import WhiteboardCalendar from "./WhiteboardCalendar/WhiteboardCalendar"
import GroupManagement from "./enterprise/GroupManagement/GroupManagement"
import EnterpriseReport from "./enterprise/EnterpriseReport"
import VendorIntegrationsMappings from "./vendor-integrations/VendorIntegrationsMappings"
import UserManagement from "./enterprise/UserManagement/UserManagement"

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/mdv/:key" element={<MergedDailyWhiteboardPage />} />
        <Route
          path="/annual_schedules/:id/whiteboard"
          element={<AnnualScheduleWhitebardPage />}
        />
        <Route path="/calendar/v2" element={<WhiteboardCalendar />} />
        <Route path="/calendar/v2/:view" element={<WhiteboardCalendar />} />
        <Route path="/enterprise/groups" element={<GroupManagement />} />
        <Route path="/enterprise/reports" element={<EnterpriseReport />} />
        <Route path="/enterprise/users" element={<UserManagement />} />
        <Route
          path="/vendor_integrations/:id"
          element={<VendorIntegrationsMappings />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default () => <Provider store={store} children={<AppRoutes />} />
