import React, { useState } from "react"
import css from "./GroupManagementTable.module.scss"
import { handleApiError } from "@app/utils"
import api from "@app/services/api"
import Modal from "@app/components/Modal"
import { format, parseISO } from "date-fns"

interface SettingsColumnsRendererProps {
  settingsColumns: string[][]
  groupSettings: GroupSettings | null
  setGroupSettings: React.Dispatch<React.SetStateAction<GroupSettings | null>>
  canEditSettings: boolean
  settingsMap: Record<string, string>
  groupid: number
}

interface GroupSettings {
  block_scheduling: boolean
  batch_import_export: boolean
  multicalendar_report: boolean
  split_shift: boolean
  always_track_splitshift: boolean
  casting_jobs: boolean
  draft_mode: boolean
  multiple_assignments: boolean
  calendar_v2: boolean
  scheduler_edit_group_merged_daily: boolean
  tigerconnect_settings: TigerConnectSetting[] | null
  [key: string]: boolean | string | TigerConnectSetting[] | null
}

interface TigerConnectSetting {
  id: string
  groupid: number
  label: string
  organization_token: string
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  created_at: string
  updated_at: string
  two_way_sync: boolean
}

const SettingsColumnsRenderer: React.FC<SettingsColumnsRendererProps> = ({
  settingsColumns,
  groupSettings,
  setGroupSettings,
  canEditSettings,
  settingsMap,
  groupid,
}) => {
  const [showDraftModeModal, setShowDraftModeModal] = useState<boolean>(false)
  const [draftModeModalContent, setDraftModeModalContent] = useState<string>("")

  const validateDisabledDraftMode = async (): Promise<boolean> => {
    try {
      const response = await api.canDisableDraftMode(groupid, {
        draft_mode_enabled: false,
      })

      const { count, start_date, end_date } = response

      if (!count || count === 0) {
        return true
      }

      const formattedStartDate = format(parseISO(start_date), "MMMM do, yyyy")
      const formattedEndDate = format(parseISO(end_date), "MMMM do, yyyy")

      const modalMessage = `
        There are <strong>${count}</strong> total Draft Mode events in this group 
        beginning on <strong>${formattedStartDate}</strong> and ending on <strong>${formattedEndDate}</strong>. 
        You must publish or delete all pending Draft Mode events 
        in this group before disabling this setting.`

      setDraftModeModalContent(modalMessage.trim())
      setShowDraftModeModal(true)
      return false
    } catch (error) {
      handleApiError(error as PlainObjectType)
      return false
    }
  }

  const modalDraftModeNotice = (
    <div className={css.modalDraftTitle}>
      <h4>Notice</h4>
    </div>
  )

  return (
    <>
      <Modal
        title={modalDraftModeNotice}
        show={showDraftModeModal}
        className={css.modal}
        size="md"
        onHide={() => setShowDraftModeModal(false)}
      >
        <div className={css.modalDraftNotice}>
          <p
            className={css.modalDraftText}
            dangerouslySetInnerHTML={{ __html: draftModeModalContent }}
          />
          <div className={css.buttonContainer}>
            <button
              className={css.saveSettings}
              onClick={() => setShowDraftModeModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>

      {settingsColumns.map((column, colIndex) => (
        <div key={colIndex} className={css.groupSettingsColumn}>
          {column.map((key) => {
            const inputId = `group-setting-${key}`
            return (
              <div key={key} className={css.groupSettingsCheckboxItem}>
                <input
                  type="checkbox"
                  id={inputId}
                  checked={
                    typeof groupSettings?.[key] === "boolean"
                      ? (groupSettings[key] as boolean)
                      : false
                  }
                  disabled={!canEditSettings}
                  onChange={async (e) => {
                    e.persist()
                    const isChecked = e.target.checked
                    if (key === "draft_mode") {
                      if (groupSettings?.draft_mode && !isChecked) {
                        const canDisable = await validateDisabledDraftMode()
                        if (!canDisable) {
                          e.target.checked = true
                          return
                        }
                      }
                    }
                    if (key === "tigerconnect_roles_sync" && isChecked) {
                      const isValid =
                        groupSettings?.tigerconnect_organization_name ||
                        groupSettings?.tigerconnect_settings?.some(
                          (setting) =>
                            setting.tigerconnect_key &&
                            setting.tigerconnect_secret &&
                            setting.organization_token &&
                            setting.integrationid
                        )
                      if (!isValid) {
                        handleApiError({
                          fail: true,
                          message:
                            "You must have one TigerConnect organization configured in order to enable the Roles sync service",
                        })
                        e.target.checked = false
                        return
                      }
                    }
                    setGroupSettings((prev) =>
                      prev ? { ...prev, [key]: isChecked } : prev
                    )
                  }}
                />
                <label htmlFor={inputId}>{settingsMap[key]}</label>
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}

export default SettingsColumnsRenderer
