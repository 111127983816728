import React, { Fragment, useState } from "react"
import Tooltip from "@app/components/Tooltip"
import css from "./GroupManagementTable.module.scss"
import { CheckCircle, Cancel } from "@material-ui/icons"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import Loader from "@app/components/Loader"

interface SettingsTableData {
  label: string
  value: string
  integrationid?: string
  two_way_sync?: boolean
}

interface TigerConnectSetting {
  id: string
  groupid: number
  label: string
  organization_token: string
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  created_at: string
  updated_at: string
  two_way_sync: boolean
}

interface SyncSettingsTableTableProps {
  settingsTableData: SettingsTableData[]
  editingRowIndex: number | null
  editingRowData: Record<number, TigerConnectSetting>
  setEditingRowIndex: React.Dispatch<React.SetStateAction<number | null>>
  setIsEditingRow: React.Dispatch<React.SetStateAction<boolean>>
  setEditingRowData: React.Dispatch<
    React.SetStateAction<Record<number, TigerConnectSetting>>
  >
  handleEditRow: (index: number) => void
  canEditSettings: boolean
}

const SyncSettingsTable: React.FC<SyncSettingsTableTableProps> = ({
  settingsTableData,
  editingRowIndex,
  editingRowData,
  setEditingRowIndex,
  setIsEditingRow,
  setEditingRowData,
  handleEditRow,
  canEditSettings,
}) => {
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null)

  const isValueEnabled = (
    value: string | boolean | undefined | null
  ): boolean => {
    if (typeof value === "boolean") {
      return value
    }
    if (!value) {
      return false
    }
    const normalizedValue = value.toString().trim().toLowerCase()
    return !["0", "false", "n/a", ""].includes(normalizedValue)
  }

  const renderStatusIcon = (enabled: boolean) => {
    return enabled ? (
      <CheckCircle style={{ color: "green" }} />
    ) : (
      <Cancel style={{ color: "red" }} />
    )
  }

  const createIntegrationRecord = async (
    rowData: TigerConnectSetting,
    index: number
  ) => {
    setLoadingIndex(index)
    try {
      const response = await api.createNewTCIntegrationId(
        rowData.organization_token,
        rowData.tigerconnect_key,
        rowData.tigerconnect_secret
      )
      console.log("Integration ID created", response)
      setEditingRowData((prev) => ({
        ...prev,
        [index]: {
          ...prev[index],
          integrationid: response.integrationid,
        },
      }))
    } catch (error) {
      handleApiError(error as PlainObjectType)
    } finally {
      setLoadingIndex(null)
    }
  }

  return (
    <table className={css.groupSettingsTable}>
      <thead>
        <tr className={css.groupSettingsTable}>
          <th className={css.tokenColumn}>Label</th>
          <th className={css.tokenColumn}>Org Token</th>
          <th className={css.tokenColumn}>API Key</th>
          <th className={css.tokenColumn}>Integration ID</th>
          <th className={css.tokenColumn}>2-way Sync</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {settingsTableData.length === 0 ||
        (settingsTableData.length === 1 &&
          settingsTableData[0].label === "0") ? (
          <tr className={css.groupSettingsTable}>
            <td colSpan={7} className={css.noDataRow}>
              No TigerConnect organizations available for this group.
            </td>
          </tr>
        ) : (
          settingsTableData
            .filter((data) => data.label !== "0")
            .map((data, index) => {
              console.log(data)
              const isEditing = editingRowIndex === index
              const rowData = editingRowData[index] || {}
              const [orgToken, apiKey, intId] = data.value
                .split(",")
                .map((item) => item.split(":")[1]?.trim() || "N/A")
              console.log(data.label)

              return (
                <Fragment key={index}>
                  <tr className={css.groupSettingsTable}>
                    <td className={css.tokenColumn}>
                      {data.label.length > 25 ? (
                        <Tooltip title={data.label}>
                          <span>{data.label}</span>
                        </Tooltip>
                      ) : (
                        <span>{data.label}</span>
                      )}
                    </td>
                    <td className={css.tokenColumn}>
                      <Tooltip title={orgToken}>
                        <span>{orgToken}</span>
                      </Tooltip>
                    </td>

                    <td className={css.tokenColumn}>
                      {renderStatusIcon(isValueEnabled(apiKey))}
                    </td>
                    <td className={css.tokenColumn}>
                      {renderStatusIcon(isValueEnabled(intId))}
                    </td>
                    <td className={css.tokenColumn}>
                      {renderStatusIcon(isValueEnabled(data.two_way_sync))}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (isEditing) {
                            setEditingRowIndex(null)
                            setIsEditingRow(false)
                          } else {
                            handleEditRow(index)
                          }
                        }}
                        className={`${css.editSettings}`}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                  {isEditing && (
                    <tr className={css.expandOrgContainer}>
                      <td colSpan={5}>
                        <div className={css.groupContainer}>
                          <div className={css.editRowContainer}>
                            <div className={css.editRow}>
                              <label>Label</label>
                              <div>
                                <input
                                  type="text"
                                  value={data.label}
                                  className={css.orgSettings}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className={css.editRow}>
                              <label>API Key</label>
                              <div>
                                <input
                                  type="text"
                                  value={apiKey}
                                  className={css.orgSettings}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className={css.editRow}>
                              <label>Roles Sync Integration ID</label>
                              <div className={css.editRowContainer}>
                                <input
                                  type="text"
                                  value={rowData?.integrationid || ""}
                                  className={css.orgSettings}
                                  disabled
                                  onChange={(e) => {
                                    setEditingRowData((prev) => ({
                                      ...prev,
                                      [index]: {
                                        ...prev[index],
                                        integrationid: e.target.value,
                                      },
                                    }))
                                  }}
                                />
                                {(!rowData.integrationid ||
                                  rowData.integrationid.trim().length ===
                                    0) && (
                                  <button
                                    className={css.buttonIntegration}
                                    onClick={() =>
                                      createIntegrationRecord(rowData, index)
                                    }
                                  >
                                    Create Integration Record
                                  </button>
                                )}
                                {loadingIndex === index && (
                                  <Loader
                                    animation="dots"
                                    className={css.loaderInt}
                                  />
                                )}
                              </div>
                            </div>
                            <div className={css.editRow}>
                              <label>2-Way Sync Enabled:</label>
                              <div>
                                <input
                                  type="checkbox"
                                  disabled={!canEditSettings}
                                  checked={rowData?.two_way_sync || false}
                                  onChange={(e) => {
                                    setEditingRowData((prev) => ({
                                      ...prev,
                                      [index]: {
                                        ...prev[index],
                                        two_way_sync: e.target.checked,
                                      },
                                    }))
                                  }}
                                />
                              </div>
                            </div>
                            <div className={css.editRow}>
                              <label>Org Token</label>
                              <div>
                                <input
                                  type="text"
                                  value={orgToken}
                                  className={css.orgSettings}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className={css.buttonContainer}>
                            <button
                              className={css.cancelSettings}
                              onClick={() => {
                                setEditingRowIndex(null)
                                setIsEditingRow(false)
                                setEditingRowData((prev) => {
                                  const updatedData = { ...prev }
                                  delete updatedData[index]
                                  return updatedData
                                })
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className={css.saveSettings}
                              onClick={() => {
                                setEditingRowIndex(null)
                                setIsEditingRow(false)
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              )
            })
        )}
      </tbody>
    </table>
  )
}

export default SyncSettingsTable
