import React, { useEffect } from "react"

import Collapse from "@app/components/Collapse"
import { FormItem, Input, useForm } from "@app/components/Form"
import { handleApiError } from "@app/utils"

import css from "./OrgCollapse.module.scss"
import api from "@app/services/api"

type PropsType = {
  label: string
  orgToken: string
  clinicId: number
  mutate: () => void
  settingId?: string
  tigerconnectKey?: string
  tigerconnectSecret?: string
  defaultEditing?: boolean
}

type FormFieldsType = {
  label: string
  tigerconnect_key: string
  tigerconnect_secret: string
  organization_token: string
}

export default ({
  label,
  orgToken,
  tigerconnectKey,
  tigerconnectSecret,
  clinicId,
  settingId,
  mutate,
  defaultEditing = false,
}: PropsType) => {
  const { control, handleSubmit, reset } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        label: yup.string().required("Label is required"),
        tigerconnect_key: yup.string().required("API Key is required"),
        tigerconnect_secret: yup.string().required("API Secret is required"),
        organization_token: yup.string(),
      }),
  })

  useEffect(() => {
    reset({
      label,
      organization_token: orgToken,
      tigerconnect_key: tigerconnectKey,
      tigerconnect_secret: tigerconnectSecret,
    })
  }, [])

  const onSubmit = (formData: FormFieldsType) => {
    api
      .createTigerconnectClinicSetting(
        clinicId,
        settingId ? { id: settingId, ...formData } : formData
      )
      .then((_res) => {
        mutate()
      }, handleApiError)
  }

  return (
    <div className="mt-2">
      <Collapse collapseIcon={false} title={label} disabled={true}>
        <form id={`org-${orgToken}-form`} onSubmit={handleSubmit(onSubmit)}>
          <FormItem control={control} required label="Label">
            <Input name="label" />
          </FormItem>
          <FormItem control={control} required label="Api Key">
            <Input name="tigerconnect_key" placeholder="Fill API Key..." />
          </FormItem>
          <FormItem control={control} required label="Api Secret">
            <Input
              name="tigerconnect_secret"
              placeholder="Fill API Secret..."
            />
          </FormItem>
          <FormItem
            name="organization_token"
            control={control}
            disabled
            label="Org Token"
          >
            <Input />
          </FormItem>
          <div className={css.buttonContainer}>
            <button type="button" className={css.cancelSettings}>
              Cancel
            </button>
            <button type="submit" className={`${css.saveSettings}`}>
              Save
            </button>
          </div>
        </form>
      </Collapse>
    </div>
  )
}
