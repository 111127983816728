import { matchPath } from "react-router-dom"
import { isArray } from "lodash"

type UserTypeName =
  | "Master"
  | "Admin"
  | "Principal"
  | "Representative"
  | "Trainer"
  | "Scheduler"
  | "Provider"

type PermissionRulesType = Record<
  string,
  {
    global?: boolean
    path?: string | string[]
    allow: UserTypeName[]
    auth?: (user: UserType, path: string) => boolean
  }[]
>

const userTypes: { id: number; type: UserTypeName }[] = [
  { id: 1, type: "Master" },
  { id: 2, type: "Admin" },
  { id: 3, type: "Principal" },
  { id: 4, type: "Representative" },
  { id: 5, type: "Trainer" },
  { id: 6, type: "Scheduler" },
  { id: 7, type: "Provider" },
]

// All access permission rules based on each scope,
// global configuration works for common permission of the scope,
// you can also set permission to specific URL path.
// Be sure you have let the global configuration in last of the rules list.
const permissionRules: PermissionRulesType = {
  blockScheduling: [
    {
      path: "/annual_schedules/:id/whiteboard",
      allow: ["Master", "Trainer", "Scheduler", "Provider"],
    },
    {
      global: true,
      allow: ["Master", "Trainer", "Scheduler"],
      auth: (currentUser, path) =>
        authMenuItems(currentUser, path, [
          {
            path: [
              "/",
              "/staff_levels/edit",
              "/rotations/edit",
              "/annual_schedules/new",
              "/annual_schedules/:id/edit",
              "/duty_hour_rules/new",
              "/duty_hour_rules/:id/edit",
            ],
            menuItems: ["settings", "setupinfo"],
          },
          { global: true, menuItems: ["scheduling"] },
        ]),
    },
  ],
  calendarV2: [
    {
      path: "/calendar/v2",
      allow: ["Trainer", "Scheduler"],
    },
  ],
  groupManagement: [
    {
      path: "/enterprise_groups",
      allow: ["Master", "Admin", "Scheduler", "Trainer", "Provider"],
    },
  ],
  UserManagement: [
    {
      path: "/user_management",
      allow: ["Master", "Admin", "Scheduler", "Trainer", "Provider"],
    },
  ],
  vendorIntegration: [
    {
      path: "/vendor_integrations/:id",
      allow: ["Admin", "Trainer"],
    },
  ],
}

function authMenuItems(
  currentUser: UserType,
  path: string,
  menuAccessRules: {
    path?: string | string[]
    global?: boolean
    menuItems: string[]
  }[] = []
) {
  const forbiddenMenuItems = currentUser.forbidden_menu_items || []

  if (!forbiddenMenuItems.length || !menuAccessRules.length) {
    return true
  }

  const rule = menuAccessRules.find((rule) => {
    const isGlobal = Boolean(rule.global)

    if (isGlobal) return true
    if (!rule.path) return false

    const paths = isArray(rule.path) ? rule.path : [rule.path]

    return paths.some((x) => matchPath(x, path))
  })

  // Say yes if no rule found/matched
  if (!rule) return true

  const isForbidden = forbiddenMenuItems.some((item) =>
    rule.menuItems.includes(item.symbol)
  )

  return !isForbidden
}

export function authPermission(
  currentUser: UserType,
  path: string,
  permissionConfig: PlainObjectType = {}
) {
  const { scope } = permissionConfig
  const userTypeId = currentUser.user_typeid
  const userType = userTypes.find((x) => x.id === userTypeId)

  if (!userType) {
    return false
  }

  const rules = permissionRules[scope] || []
  const rule = rules.find((rule) => {
    const isGlobal = Boolean(rule.global)

    if (isGlobal) return true
    if (!rule.path) return false

    const paths = isArray(rule.path) ? rule.path : [rule.path]

    return paths.some((x) => matchPath(x, path))
  })

  if (rule) {
    const isAllowed = rule.allow.includes(userType.type)

    if (isAllowed) {
      return rule.auth ? rule.auth(currentUser, path) : true
    } else {
      return false
    }
  }

  return true
}
