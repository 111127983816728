import React, { useEffect, useState } from "react"

import Collapse from "@app/components/Collapse"
import { FormItem, Input, useForm } from "@app/components/Form"
import PopoverMenu from "@app/components/PopoverMenu"
import { handleApiError } from "@app/utils"
import { Dialog } from "@app/components/Modal"

import css from "../SettingsContentModal.module.scss"
import api from "@app/services/api"

type PropsType = {
  label: string
  orgToken: string
  clinicId: number
  mutate: () => void
  settingId?: string
  tigerconnectKey?: string
  tigerconnectSecret?: string
  defaultEditing?: boolean
}

type FormFieldsType = {
  label: string
  tigerconnect_key: string
  tigerconnect_secret: string
  organization_token: string
}

export default ({
  label,
  orgToken,
  tigerconnectKey,
  tigerconnectSecret,
  clinicId,
  settingId,
  mutate,
  defaultEditing = false,
}: PropsType) => {
  const [editing, setEditing] = useState(defaultEditing)
  const [open, setOpen] = useState(false)

  const { control, handleSubmit, reset } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        label: yup.string().required("Label is required"),
        tigerconnect_key: yup.string().required("API Key is required"),
        tigerconnect_secret: yup.string().required("API Secret is required"),
        organization_token: yup.string(),
      }),
  })

  useEffect(() => {
    reset({
      label,
      organization_token: orgToken,
      tigerconnect_key: tigerconnectKey,
      tigerconnect_secret: tigerconnectSecret,
    })
  }, [])

  const onSubmit = (formData: FormFieldsType) => {
    api
      .createTigerconnectClinicSetting(
        clinicId,
        settingId ? { id: settingId, ...formData } : formData
      )
      .then((_res) => {
        mutate()
        setOpen(false)
        setEditing(true)
      }, handleApiError)
  }

  const handleDelete = () => {
    if (settingId) {
      api.deleteTigerconnectClinicSetting(settingId).then((_res) => {
        mutate()
      })
    }
  }

  const handlePropagate = () => {
    if (settingId) {
      api
        .propagateTigerconnectClinicSetting(settingId, clinicId)
        .then((_res) => {
          Dialog.success({
            title: "Success",
            message: "Propagated setting to groups.",
          })
        }, handleApiError)
    }
  }

  return (
    <div className="mt-2">
      <Collapse
        customToggleButton={
          editing ? (
            <PopoverMenu
              id={`popover-${orgToken}`}
              buttons={[
                {
                  text: "Edit",
                  onClick: () => setOpen(true),
                },
                {
                  text: "Propagate",
                  onClick: handlePropagate,
                },
                {
                  text: "Remove",
                  onClick: handleDelete,
                  variant: "red",
                },
              ]}
            />
          ) : (
            <a onClick={() => setOpen(true)}>Add org</a>
          )
        }
        collapseIcon={false}
        title={label}
        variant="borders"
        disabled={true}
        isOpen={open}
      >
        <form id={`org-${orgToken}-form`} onSubmit={handleSubmit(onSubmit)}>
          <FormItem control={control} required label="Label">
            <Input name="label" />
          </FormItem>
          <FormItem control={control} required label="Api Key">
            <Input name="tigerconnect_key" placeholder="Fill API Key..." />
          </FormItem>
          <FormItem control={control} required label="Api Secret">
            <Input
              name="tigerconnect_secret"
              placeholder="Fill API Secret..."
            />
          </FormItem>
          <FormItem
            name="organization_token"
            control={control}
            disabled
            label="Org Token"
          >
            <Input />
          </FormItem>
          <div className={css.buttonContainer}>
            <button
              type="button"
              className={css.cancelSettings}
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button type="submit" className={`${css.saveSettings}`}>
              Save
            </button>
          </div>
        </form>
      </Collapse>
    </div>
  )
}
