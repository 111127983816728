import React, { useEffect, useState } from "react"
import { FormItem, Input } from "@app/components/Form"
import OrgCollapse from "./OrgCollapse"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import Loader from "@app/components/Loader"

import css from "./AddOrgsToGroup.module.scss"

type OrgResults = {
  name: string
  token: string
}[]

type PropsType = {
  clinicId: number
  groupId: number
  goBack: () => void
}

export default ({ clinicId, groupId, goBack }: PropsType) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [orgSearchLoading, setOrgSearchLoading] = useState(false)
  const [orgResults, setOrgResults] = useState<OrgResults>([])
  const [selectedOrgsToAdd, setSelectedOrgsToAdd] = useState<
    { index: number; value: TigerconnectGroupSetting }[]
  >([])

  const { data: existingSettings, mutate } = useRequest<
    TigerconnectClinicSetting[]
  >([api.getTigerconnectClinicSettings, clinicId, groupId])

  useEffect(() => {
    if (searchTerm.length > 3) {
      setOrgSearchLoading(true)
      api.searchTigerconnectOrganizations(searchTerm).then((results) => {
        const existingSettingOrgTokens = existingSettings?.map(
          (setting) => setting.organization_token
        )
        setOrgResults(
          results.filter((result: any) =>
            !existingSettingOrgTokens
              ? true
              : !existingSettingOrgTokens.includes(result.token)
          )
        )
        setOrgSearchLoading(false)
      }, handleApiError)
    }
    setOrgSearchLoading(false)
  }, [searchTerm, existingSettings])

  const addSingleOrg = (orgToAdd: {
    index: number
    value: TigerconnectGroupSetting
  }) => {
    const { value: tigerconnectSetting } = orgToAdd
    const currentOrgs = [...selectedOrgsToAdd]
    const existingOrgIndex = currentOrgs.findIndex(
      ({ value: org }) =>
        org.organization_token === tigerconnectSetting.organization_token &&
        org.tigerconnect_key === tigerconnectSetting.tigerconnect_key &&
        org.tigerconnect_secret === tigerconnectSetting.tigerconnect_secret
    )
    if (existingOrgIndex > -1) {
      currentOrgs.splice(existingOrgIndex, 1, {
        index: existingOrgIndex,
        value: tigerconnectSetting,
      })
      setSelectedOrgsToAdd(currentOrgs)
    } else {
      setSelectedOrgsToAdd([
        ...selectedOrgsToAdd,
        { index: selectedOrgsToAdd.length, value: tigerconnectSetting },
      ])
    }
  }

  const saveOrgs = () => {
    api
      .createTigerconnectSettings(
        selectedOrgsToAdd.map((setting) => ({
          ...setting.value,
          groupid: groupId,
        }))
      )
      .then((_res) => {
        goBack()
      }, handleApiError)
  }

  const removevOrg = (index: number) => {
    const existingSelectedOrgs = [...selectedOrgsToAdd]
    existingSelectedOrgs.splice(index, 1)
    setSelectedOrgsToAdd(existingSelectedOrgs)
  }

  return (
    <div className="mt-3">
      <FormItem label="Add orgs" layout="vertical">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Organizations"
        />
      </FormItem>
      {existingSettings?.map((setting, index) => (
        <OrgCollapse
          index={index}
          key={`${setting.organization_token}_${index}`}
          label={setting.label}
          tigerconnectKey={setting.tigerconnect_key}
          tigerconnectSecret={setting.tigerconnect_secret}
          orgToken={setting.organization_token}
          settingId={setting.id}
          mutate={mutate}
          clinicId={clinicId}
          addOrg={addSingleOrg}
          removeOrg={removevOrg}
        />
      ))}
      {orgSearchLoading && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}
      {orgResults.map((org, index) => (
        <OrgCollapse
          index={index}
          key={org.token}
          mutate={mutate}
          clinicId={clinicId}
          label={org.name}
          orgToken={org.token}
          addOrg={addSingleOrg}
          removeOrg={removevOrg}
        />
      ))}
      <div className={css.buttonContainer}>
        <button type="button" className={css.cancelSettings} onClick={goBack}>
          Cancel
        </button>
        <button
          type="button"
          className={`${css.saveSettings}`}
          onClick={saveOrgs}
        >
          Save
        </button>
      </div>
    </div>
  )
}
