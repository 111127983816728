import React, { useEffect, useState } from "react"

import Collapse from "@app/components/Collapse"
import { Checkbox, FormItem, Input, useForm } from "@app/components/Form"

import css from "./OrgCollapse.module.scss"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"

type PropsType = {
  index: number
  label: string
  orgToken: string
  clinicId: number
  mutate: () => void
  addOrg: (orgAdded: { index: number; value: TigerconnectGroupSetting }) => void
  removeOrg: (index: number) => void
  settingId?: string
  tigerconnectKey?: string
  tigerconnectSecret?: string
  defaultEditing?: boolean
}

type FormFieldsType = {
  label: string
  tigerconnect_key: string
  tigerconnect_secret: string
  organization_token: string
  two_way_sync: boolean
  integrationid: string
}

export default ({
  index,
  removeOrg,
  label,
  orgToken,
  tigerconnectKey,
  tigerconnectSecret,
  addOrg,
}: PropsType) => {
  const [open, setOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<FormFieldsType>({
      schema: (yup) =>
        yup.object().shape({
          label: yup.string(),
          tigerconnect_key: yup.string(),
          tigerconnect_secret: yup.string(),
          organization_token: yup.string(),
          two_way_sync: yup.boolean(),
          integrationid: yup.string(),
        }),
    })

  useEffect(() => {
    reset({
      label,
      organization_token: orgToken,
      tigerconnect_key: tigerconnectKey,
      tigerconnect_secret: tigerconnectSecret,
      two_way_sync: false,
      integrationid: "",
    })
  }, [])

  const createIntegrationRecord = () => {
    const formValues = getValues()
    const { tigerconnect_key, tigerconnect_secret, organization_token } =
      formValues

    if (
      !tigerconnect_key ||
      !tigerconnect_key.length ||
      !tigerconnect_secret ||
      !tigerconnect_secret.length ||
      !organization_token ||
      !organization_token.length
    ) {
      handleApiError({
        message:
          "API Key, API Secret, and Organization Token are required to create a new integration record",
      })
    } else {
      api
        .createNewTCIntegrationId(
          organization_token,
          tigerconnect_key,
          tigerconnect_secret
        )
        .then((res) => {
          setValue("integrationid", res.integrationid)
        })
        .catch((error) => {
          let message = "There was an error creating the new Integration Id."
          if (error.message.code == 401) {
            message += " The API Key and Secret failed to authorize."
          }
          handleApiError({ message: message })
        })
    }
  }

  const saveOrg = (formValues: FormFieldsType) => {
    addOrg({ index, value: formValues })
    setIsEditing(true)
    setOpen(false)
  }

  return (
    <div className="mt-2">
      <Collapse
        customToggleButton={
          !isEditing ? (
            <a onClick={() => setOpen(true)}>Add org</a>
          ) : (
            <p
              onClick={() => {
                removeOrg(index)
                setIsEditing(false)
              }}
              className={css.removeOrg}
            >
              Remove Org
            </p>
          )
        }
        collapseIcon={false}
        title={label}
        variant="borders"
        disabled={true}
        isOpen={open}
      >
        <form id={`org-${orgToken}-form`} onSubmit={handleSubmit(saveOrg)}>
          <FormItem readOnly control={control} required label="Label">
            <Input name="label" />
          </FormItem>
          <FormItem readOnly control={control} required label="Api Key">
            <Input name="tigerconnect_key" placeholder="Fill API Key..." />
          </FormItem>
          <FormItem label="Role Sync Integration ID">
            <Input name="integrationid" control={control} />
            <div className="mt-3 mb-3">
              <a href="#" onClick={createIntegrationRecord}>
                Create Integration Record
              </a>
            </div>
          </FormItem>
          <FormItem name="two_way_sync" control={control} label="Two Way Sync">
            <Checkbox />
          </FormItem>
          <FormItem
            name="organization_token"
            control={control}
            readOnly
            label="Org Token"
          >
            <Input />
          </FormItem>
          <div className={css.buttonContainer}>
            <button
              type="button"
              className={css.cancelSettings}
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button type="submit" className={`${css.saveSettings}`}>
              Save
            </button>
          </div>
        </form>
      </Collapse>
    </div>
  )
}
