import React from "react"

import css from "./VendorMappingsHeader.module.scss"
import cx from "classnames"
import Loader from "@app/components/Loader"

type PropsType = {
  vendorIntegration: VendorIntegration | undefined
  showModal: () => void
}

export default ({ vendorIntegration, showModal }: PropsType) => {
  return vendorIntegration ? (
    <div
      className={cx(css.headerContainer, "mt-3 d-flex justify-content-between")}
    >
      <h3 className="font-weight-bold">
        Data mapping management for {vendorIntegration?.id || "Loading..."}
      </h3>
      <button onClick={showModal} className={css.customButton}>
        Add Mapping
      </button>
    </div>
  ) : (
    <Loader animation="dots" />
  )
}
