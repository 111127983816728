import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import cx from "classnames"
import css from "./VendorIntegrationsMappings.module.scss"

import { useDispatch, useSelector } from "@app/models"
import api from "@app/services/api"
import Loader from "@app/components/Loader"
import { Container } from "@app/components/Layout"
import Menu from "@app/containers/layouts/Menu"
import Tabs from "@app/components/Tabs"

import Mappings from "./mappings/Mappings"
import UnmappedItems from "./unmapped-items"
import { handleApiError } from "@app/utils"
import { useAccessPermission, useIsAuthenticated } from "@app/hooks"

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.users)
  const [vendorIntegration, setVendorIntegration] =
    useState<VendorIntegration>()
  const isAuthenticated = useIsAuthenticated()
  const { canAccess } = useAccessPermission({ scope: "vendorIntegration" })

  useEffect(() => {
    ;(async () => {
      await dispatch.users.getCurrentUserInfo()
    })()
    if (id) {
      api.getVendorIntegration(id).then((vendor) => {
        setVendorIntegration(vendor)
      }, handleApiError)
    }
  }, [])

  useEffect(() => {
    if (!isAuthenticated) return

    if (isAuthenticated) {
      if (!canAccess("/vendor_integrations")) {
        window.location.href = "/menu.cgi"
      }
    }
  }, [isAuthenticated, canAccess])

  const tabs = [
    {
      title: "Mappings",
      eventKey: "mappings",
      content: vendorIntegration ? (
        <Mappings vendorIntegration={vendorIntegration} />
      ) : (
        <Loader animation="dots" />
      ),
    },
    {
      title: "Unmapped Items",
      eventKey: "unmappedItems",
      content: vendorIntegration ? (
        <UnmappedItems />
      ) : (
        <Loader animation="dots" />
      ),
    },
  ]

  return (
    <div className="bootstrap4">
      <Menu userInfo={currentUser} />
      <Container
        size="fluid"
        className={cx(css.mappingsContainer, "pt-3 pb-3")}
      >
        <Tabs variant="underline-blue" tabs={tabs} />
      </Container>
    </div>
  )
}
