import React from "react"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"

type PropsType = {
  mappings: VendorIntegrationMapping[] | undefined
  showEditModal: (mapping: VendorIntegrationMapping) => void
  mutate: () => void
  onHide: () => void
}

export default ({ mappings, showEditModal, mutate, onHide }: PropsType) => {
  const deactivateMapping = (mappingId: string) => {
    api
      .updateVendorIntegrationMapping(mappingId, {
        inactive: true,
      })
      .then((_res) => {
        mutate()
        onHide()
      }, handleApiError)
  }

  return (
    <Table
      variant="spaceBetween"
      loading={!mappings}
      data={mappings || []}
      columns={[
        {
          dataKey: "vendor_integrationid",
          title: "Integration ID",
        },
        {
          dataKey: "mapping_type",
          title: "Mapping Type",
          filter: {
            options: [
              {
                id: "user",
                name: "Users",
              },
              {
                id: "group",
                name: "Groups",
              },
              {
                id: "job",
                name: "Jobs",
              },
            ],
            multiple: true,
          },
        },
        {
          dataKey: "external_id",
          title: "External ID",
        },
        {
          dataKey: "internal_id",
          title: "TCPS ID",
        },
        {
          dataKey: "internal_name",
          title: "TCPS Name",
        },
        {
          dataKey: "external_name",
          title: "External Name",
        },
        {
          dataKey: "updated_at",
          title: "Timestamp",
          sorter: true,
        },
        {
          dataKey: "created_by",
          title: "Created By",
        },
        {
          dataKey: "last_updated_by",
          title: "Last Updated By",
        },
        {
          title: "Operations",
          render: (_, mapping) => {
            return (
              <div>
                <Button
                  onClick={() => showEditModal(mapping)}
                  className="mr-2"
                  size="sm"
                  variant="outline-primary"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => deactivateMapping(mapping.id)}
                  size="sm"
                  variant="outline-danger"
                >
                  Deactivate
                </Button>
              </div>
            )
          },
        },
      ]}
    />
  )
}
