import React, { useEffect, useState } from "react"
import { FormItem, Input } from "@app/components/Form"
import AddGroupOrgCollapse from "./AddGroupOrgCollapse"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import Loader from "@app/components/Loader"

type OrgResults = {
  name: string
  token: string
}[]

export type TigerconnectClinicSetting = {
  id?: string
  label: string
  tigerconnect_key: string
  tigerconnect_secret: string
  integrationid: string
  two_way_sync: boolean
  organization_token: string
}

type PropsType = {
  clinicId: number
  onOrgsSelected: (orgs: TigerconnectGroupSetting[]) => void
  initialSelectedOrgs?: TigerconnectGroupSetting[]
}

export default ({
  clinicId,
  onOrgsSelected,
  initialSelectedOrgs = [],
}: PropsType) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [orgSearchLoading, setOrgSearchLoading] = useState(false)
  const [orgResults, setOrgResults] = useState<OrgResults>([])
  const [selectedOrgsToAdd, setSelectedOrgsToAdd] =
    useState<TigerconnectGroupSetting[]>(initialSelectedOrgs)

  const { data: defaultSettings } = useRequest<TigerconnectClinicSetting[]>([
    api.getTigerconnectClinicSettings,
    clinicId,
  ])

  useEffect(() => {
    if (searchTerm.length > 3) {
      setOrgSearchLoading(true)
      api.searchTigerconnectOrganizations(searchTerm).then((results) => {
        const defaultSettingOrgTokens = defaultSettings?.map(
          (setting) => setting.organization_token
        )
        const filteredResults = results.filter(
          (result: any) =>
            defaultSettingOrgTokens?.includes(result.token) || false
        )
        setOrgResults(filteredResults)
        setOrgSearchLoading(false)
      }, handleApiError)
    } else {
      setOrgResults([])
    }
    setOrgSearchLoading(false)
  }, [searchTerm, defaultSettings])

  useEffect(() => {
    onOrgsSelected(selectedOrgsToAdd)
  }, [selectedOrgsToAdd])

  const handleSaveOrg = (org: TigerconnectGroupSetting) => {
    setSelectedOrgsToAdd((current) => {
      const index = current.findIndex(
        (item) => item.organization_token === org.organization_token
      )
      if (index > -1) {
        const newOrgs = [...current]
        newOrgs[index] = org
        return newOrgs
      }
      return [...current, org]
    })
  }

  const handleRemoveOrg = (orgToken: string) => {
    setSelectedOrgsToAdd((current) =>
      current.filter((org) => org.organization_token !== orgToken)
    )
  }

  const filterBySearchTerm = (org: TigerconnectClinicSetting) => {
    if (!searchTerm) return true
    return org.label.toLowerCase().includes(searchTerm.toLowerCase())
  }

  const filterSearchResults = (org: { name: string; token: string }) => {
    if (!searchTerm) return true
    return org.name.toLowerCase().includes(searchTerm.toLowerCase())
  }

  if (!defaultSettings?.length) {
    return (
      <div className="text-center p-4">
        <p>No default organization settings found.</p>
      </div>
    )
  }

  return (
    <div>
      <FormItem label="Search organizations" layout="vertical">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Organizations"
          value={searchTerm}
        />
      </FormItem>

      {orgSearchLoading && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}

      {defaultSettings?.filter(filterBySearchTerm).map((setting, index) => (
        <AddGroupOrgCollapse
          key={`${setting.organization_token}_${index}`}
          setting={setting}
          onSave={handleSaveOrg}
          onRemove={handleRemoveOrg}
          isSelected={selectedOrgsToAdd.some(
            (org) => org.organization_token === setting.organization_token
          )}
        />
      ))}

      {!orgSearchLoading &&
        defaultSettings?.filter(filterBySearchTerm).length === 0 &&
        orgResults.filter(filterSearchResults).length === 0 && (
          <div className="text-center p-4">
            <p>No organizations found matching your search.</p>
          </div>
        )}

      {orgResults.filter(filterSearchResults).map((org, index) => (
        <AddGroupOrgCollapse
          key={`search_${org.token}_${index}`}
          setting={{
            label: org.name,
            organization_token: org.token,
            tigerconnect_key: "",
            tigerconnect_secret: "",
            integrationid: "",
            two_way_sync: false,
          }}
          onSave={handleSaveOrg}
          onRemove={handleRemoveOrg}
          isSelected={selectedOrgsToAdd.some(
            (item) => item.organization_token === org.token
          )}
        />
      ))}
    </div>
  )
}
